import service from "@/http/request";
// 获取验证码
// @Summary 获取验证码
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/captcha [post]
export const captcha = (data) => {
  return service({
    url: "/base/captcha",
    method: "post",
    data: data
  });
};

// @Summary 用户登录
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/login [post]
export const login = (data) => {
  return service({
    url: "/base/login",
    method: "post",
    data: data
  });
}
// 获取token
export const getToken = (data) => {
  return service({
    url: "/base/jwt/getToken",
    method: "post",
    data: data
  });
};
// 修改用户密码
export const changePassword = (data) => {
  return service({
    url: "/api/changePassword",
    method: "post",
    data: data
  });
};

