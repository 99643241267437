//对axios二次封装
import Axios from "axios";
import store from "@/store";
import { ElMessage } from "element-plus";

//引入进度条依赖
// import nProgress from "nprogress";
// import "nprogress/nprogress.css";

//引入路由
import router from "@/router";

//创建一个axios实例
const service = Axios.create({
  //请求基地址
  baseURL: "/api",
  // baseURL: "http://111.13.137.74:21702",
  //请求超时时间，单位：毫秒
  timeout: 9999999999
});

//设置请求拦截器
service.interceptors.request.use(
  (config) => {
    // nProgress.start();

    config.headers = {
      // "Content-Type": "application/json",
      Authorization: JSON.parse(window.localStorage.getItem("token")) || ""
    };

    return config;
  },
  (err) => {
    console.log(
      "%c" + "请求发生错误",
      "background: #f00; color: #fff; border-radius:3px;padding:4px 10px;font-size:14px;font-family:" +
      "微软雅黑",
      err
    );
    ElMessage({
      showClose: true,
      message: err,
      type: 'error'
    })
    return Promise.reject(err);
  }
);

//响应拦截器
service.interceptors.response.use(
  (res) => {
    // nProgress.done();
    if (res.data.code === 0 || res.headers.success === "true") {
      if (res.headers.msg) {
        res.data.msg = decodeURI(res.headers.msg);
      }
      return Promise.resolve(res && res.data);
    } else {
      // ========================
      if (res.data.msg || decodeURI(res.headers.msg)) {
        if (res.data instanceof Blob) {
          ElMessage({
            showClose: true,
            message: '导出成功',
            type: 'success'
          })
        } else {
          ElMessage({
            showClose: true,
            message: res.data.msg || decodeURI(res.headers.msg),
            type: 'error'
          })
        }
      }
      // ========================
      if (res.data.data && res.data.data.reload || res.data.code == 999) {
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("token");
        router.push("/login");
      }
      return Promise.resolve(res.data);
    }
  },
  (err) => {
    // console.log(
    //   "%c" + "响应发生错误",
    //   "background: #f00; color: #fff; border-radius:3px;padding:4px 10px;font-size:14px;font-family:" +
    //   "微软雅黑",
    //   err
    // );

    switch (err.response.status) {
      case 404:
        ElMessage({
          message: err?.response?.data || "找不到资源",
          type: "error"
        });
        break;

      default:
        if (err.response.status === 401 && err.response.statusText === "Unauthorized") {
          ElMessage({
            message: "登录态失效请重新登录",
            type: "error"
          });
          window.localStorage.removeItem("userInfo");
          window.localStorage.removeItem("token");
          router.push("/login");
        } else {
          ElMessage({
            message: err?.response?.data,
            type: "error"
          });
        }
        break;
    }

    return Promise.reject(err);
  }
);

export default service;
