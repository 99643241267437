import { createApp } from 'vue'
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import zhCn from 'element-plus/es/locale/lang/zh-cn'
import './style/element_visiable.scss'//自定义主题

import i18n from './i18n'

import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

import JsonExcel from "vue-json-excel3";
app.component("downloadExcel", JsonExcel);


// import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
// import ContextMenu from '@imengyu/vue3-context-menu'
// import vue3TreeVue from 'vue3-tree-vue'



app.use(store).use(router).use(ElementPlus).use(i18n).mount("#app");
