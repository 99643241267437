import { createRouter, createWebHashHistory } from "vue-router";

import Home from "../views/Home.vue";
import store from "@/store";
const routes = [
  {
    redirect: "/login",
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "仪表盘",
          auth: true
        },
        component: () => import("../views/dashboard/index.vue")
      },
      {
        path: "/smsSend",
        name: "smsSend",
        meta: {
          title: "发送短信",
          auth: true
        },
        component: () => import("../views/smsSend/index.vue")
      },
      {
        path: "/addressBook",
        name: "addressBook",
        meta: {
          title: "通讯录",
          auth: true
        },
        component: () => import("../views/addressBook/index.vue")
      },
      {
        path: "/appManage",
        name: "appManage",
        meta: {
          title: "应用管理",
          auth: true
        },
        component: () => import("../views/appManage/index.vue")
      },
      {
        path: "/my",
        name: "my",
        meta: {
          title: "我的",
          auth: true
        },
        component: () => import("../views/my/index.vue")
      },
      {
        path: "/sendDetail",
        name: "sendDetail",
        meta: {
          title: "发送明细",
          auth: true
        },
        component: () => import("../views/sendDetail/index.vue")
      },
      {
        path: "/smsUpLog",
        name: "smsUpLog",
        meta: {
          title: "上行回复",
          auth: true
        },
        component: () => import("../views/smsUpLog/index.vue")
      },
      {
        path: "/statisticalManage",
        name: "statisticalManage",
        meta: {
          title: "统计管理",
          auth: true
        },
        component: () => import("../views/statisticalManage/index.vue"),
        // children: [
        //   {
        //     path: "/statisticalManage/customerSend",
        //     name: "smsSendStatistical",
        //     meta: {
        //       title: "客户统计",
        //       auth: true
        //     },
        //     component: () => import("../views/statisticalManage/customerSend.vue"),
        //   },
        // ]
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "欢迎登录用户短信发送平台",
      auth: false
    },
    component: () => import("../views/login/index.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// 路由前置
router.beforeEach((to, from, next) => {
  if (
    to.name === "login" &&
    JSON.parse(window.localStorage.getItem("userInfo")) &&
    JSON.parse(window.localStorage.getItem("token"))
  ) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

// 路由后置
router.afterEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
