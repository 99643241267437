export default {
    logo: '云信通短信平台',
    menu: {
        authName44: '用户与产品管理',
        authName48: '用户列表',
        authName46: '产品列表',
        authName47: '产品树',
        authName45: '补发策略',
        authName49: '通道管理',
        authName50: '通道列表',
        authName51: '通道池列表',
        authName62: '短信发送',
        authName35: '短信管理',
        authName37: '拦截记录',
        authName38: '上行回复',
        authName36: '发送明细',
        authName39: '短信安全',
        authName40: '黑白名单',
        authName43: '短信审核',
        authName42: '审核日志',
        authName41: '关键字管理',
        authName65: '财务管理',
        authName67: '客户充值记录',
        authName68: '客户调价记录',
        authName81: '通道调价记录',
        authName84: '客户回款记录',
        authName94: '归档返还记录',
        authName58: '企业管理',
        authName60: '企业',
        authName59: '企业组',
        authName52: '统计管理',
        authName73: '客户发送统计',
        authName74: '通道发送统计',
        authName75: '客户经理统计',
        authName76: '按天统计',
        authName77: '分省统计',
        authName78: '错误码统计',
        authName79: '签名统计',
        authName80: '发送速度统计',
        authName85: '利润统计',
        authName63: '监控服务',
        authName82: '通道监控',
        authName83: '用户发送监控',
        authName69: '系统管理',
        authName70: '号码地区',
        authName71: '退订关键词',
        authName93: '错误码对照表',
        authName86: '告警管理',
        authName91: '联系人',
        authName90: '告警场景',
        authName88: '活跃告警',
        authName92: '通知组',
        authName89: '历史告警',
        authName87: '告警规则',
        authName1: '仪表盘',
        authName3: '超级管理员',
        authName19: '字典详情',
        authName4: '角色管理',
        authName5: '菜单管理',
        authName6: 'api管理',
        authName7: '用户管理',
        authName18: '字典管理',
        authName20: '操作历史',
        authName8: '个人信息',
        authName14: '系统工具',
        authName25: '自动化代码（复用）',
        authName26: '自动化package',
        authName15: '代码生成器',
        authName24: '自动化代码管理',
        authName16: '表单生成器',
        authName17: '系统配置',
        authName27: '自动化插件模板',
        authName9: '示例文件',
        authName10: 'excel导入导出',
        authName11: '媒体库（上传下载）',
        authName12: '断点续传',
        authName13: '客户列表（资源示例）',
        authName2: '关于我们',
        authName22: '官方网站',
        authName23: '服务器状态',
    },
    history: {
        "404": "迷路了*。*",
        "userAndproductManage": "用户与产品管理",
        "channelMgt": "通道管理",
        "smsSend": "短信发送",
        "smsMgt": "短信管理",
        "smsSecurity": "短信安全",
        "financialManage": "财务管理",
        "enterpriseManage": "企业管理",
        "statisticalManage": "统计管理",
        "monitorService": "监控服务",
        "systemManage": "系统管理",
        "alertManage": "告警管理",
        "dashboard": "仪表盘",
        "superAdmin": "超级管理员",
        "person": "个人信息",
        "systemTools": "系统工具",
        "example": "示例文件",
        "about": "关于我们",
        "https://www.gin-vue-admin.com": "官方网站",
        "state": "服务器状态",
        "Reload": "",
        // =======
        "userList": "用户列表",
        "productList": "产品列表",
        "productTree": "产品树",
        "reissue": "补发策略",
        "channelList": "通道列表",
        "channelPoolList": "通道池列表",
        "smsSend": "短信发送",
        "interceptLog": "拦截记录",
        "smsUpLog": "上行回复",
        "sendDetail": "发送明细",
        "blackWhiteList": "黑白名单",
        "auditSms": "短信审核",
        "auditLog": "审核日志",
        "keyword": "关键字管理",
        "rechargeRecord": "客户充值记录",
        "modifyPriceLog": "客户调价记录",
        "channelModifyPriceRecord": "通道调价记录",
        "customerRepaymentRecord": "客户回款记录",
        "customerCashBackRecord": "归档返还记录",
        "enterpriseInfo": "企业",
        "enterpriseGroupInfo": "企业组",
        "customerSend": "客户发送统计",
        "channelSend": "通道发送统计",
        "accountManager": "客户经理统计",
        "dayStatistics": "按天统计",
        "provinceStatistics": "分省统计",
        "errorCodeStatistics": "错误码统计",
        "signature": "签名统计",
        "sendSpeed": "发送速度统计",
        "profit": "利润统计",
        "channelMonitoring": "通道监控",
        "userSendMonitoring": "用户发送监控",
        "numberRegion": "号码地区",
        "unsubscribeKeywords": "退订关键词",
        "errReportCode": "错误码对照表",
        "contactPerson": "联系人",
        "instance": "告警场景",
        "active": "活跃告警",
        "notificationGroup": "通知组",
        "history": "历史告警",
        "rules": "告警规则",
        "dashboard": "仪表盘",
        "authority": "角色管理",
        "menu": "菜单管理",
        "api": "api管理",
        "user": "用户管理",
        "dictionary": "字典管理",
        "operation": "操作历史",
        "autoPkg": "自动化package",
        "autoCode": "代码生成器",
        "autoCodeAdmin": "自动化代码管理",
        "formCreate": "表单生成器",
        "system": "系统配置",
        "autoPlug": "自动化插件模板",
        "excel": "excel导入导出",
        "upload": "媒体库（上传下载）",
        "breakpoint": "断点续传",
        "customer": "客户列表（资源示例）",
        "about": "关于我们",
        "https://www.gin-vue-admin.com": "官方网站",
        "state": "服务器状态"
    },
    "云信通短信平台": "云信通短信平台",
    "关键字类型名": "关键字类型名",
    "关键字策略名称": "关键字策略名称",
    "手机号": "手机号",
    "充值前余额": "充值前余额",
    "充值金额": "充值金额",
    "充值后余额": "充值后余额",
    "充值类型": "充值类型",
    "充值方式": "充值方式",
    "操作人": "操作人",
    "充值时间": "充值时间",
    "确认回账时间": "确认回账时间",
    "调整前单价": "调整前单价",
    "调整后单价": "调整后单价",
    "调整时间": "调整时间",
    "生效时间": "生效时间",
    "生效状态": "生效状态",
    "通道名": "通道名",
    "省内": "省内",
    "省外": "省外",
    "回款前余额": "回款前余额",
    "回款金额": "回款金额",
    "回款后余额": "回款后余额",
    "回款方式": "回款方式",
    "回款时间": "回款时间",
    "归档用户": "归档用户",
    "归档金额": "归档金额",
    "归档类型": "归档类型",
    "归档时间": "归档时间",
    "操作时间": "操作时间",
    "企业名称": "企业名称",
    "所属企业组": "所属企业组",
    "联系人": "联系人",
    "联系电话": "联系电话",
    "企业全称": "企业全称",
    "企业简称": "企业简称",
    "邮箱": "邮箱",
    "企业组名称": "企业组名称",
    "发送总量": "发送总量",
    "未提交": "未提交",
    "拦截": "拦截",
    "黑名单": "黑名单",
    "审核失败": "审核失败",
    "发送成功": "发送成功",
    "发送失败": "发送失败",
    "未知状态": "未知状态",
    "未知率": "未知率",
    "成功率": "成功率",
    "协议类型": "协议类型",
    "总提交": "总提交",
    "成功": "成功",
    "失败": "失败",
    "未知": "未知",
    "客户经理": "客户经理",
    "统计时间": "统计时间",
    "提交成功率": "提交成功率",
    "网关成功率": "网关成功率",
    "日期": "日期",
    "表格": "表格",
    "图表": "图表",
    "失败回执": "失败回执",
    "发送数量": "发送数量",
    "时间段": "时间段",
    "错误码": "错误码",
    "发送量": "发送量",
    "数量": "数量",
    "占比": "占比",
    "总计": "总计",
    "应用价格": "应用价格",
    "应用计费类型": "应用计费类型",
    "通道价格": "通道价格",
    "通道计费类型": "通道计费类型",
    "应用付费类型": "应用付费类型",
    "应用所属企业": "应用所属企业",
    "应用所属销售": "应用所属销售",
    "应收金额": "应收金额",
    "实际成本": "实际成本",
    "利润": "利润",
    "号段": "号段",
    "所属省": "所属省",
    "所属市": "所属市",
    "包含词": "包含词",
    "含义": "含义",
    "提成比例": "提成比例",
    "是否允许登录": "是否允许登录",
    "所属销售": "所属销售",
    "所属企业": "所属企业",
    "短信发送平台": "短信发送平台",
    "登录": "登录",
    "清空": "清空",
    "用户名": "用户名",
    "密码": "密码",
    "请正确填写登录信息": "请正确填写登录信息",
    "请输入正确的用户名": "请输入正确的用户名",
    "请输入正确的密码": "请输入正确的密码",
    "请输入验证码": "请输入验证码",
    "请输入账号": "请输入账号",
    "请输入密码": "请输入密码",
    "验证码格式不正确": "验证码格式不正确",
    "登录成功": "登录成功",
    "登录中，请稍后...": "登录中，请稍后...",
    "已配置数据库信息，无法初始化": "已配置数据库信息，无法初始化",
    "当前温度": "当前温度",
    "更新时间": "更新时间",
    "月发送量": "月发送量",
    "上午好": "上午好",
    "请开始一天的工作吧": "请开始一天的工作吧",
    "中午好": "中午好",
    "记得吃午饭哦": "记得吃午饭哦",
    "下午好": "下午好",
    "长时间工作要注意短暂休息哦": "长时间工作要注意短暂休息哦",
    "晚上好": "晚上好",
    "加油！你是最棒哒！": "加油！你是最棒哒！",
    "夜深了": "夜深了",
    "注意休息哦": "注意休息哦",
    "统计": "统计",
    "待审核(条)": "待审核(条)",
    "待发(条)": "待发(条)",
    "当日发送(条)": "当日发送(条)",
    "昨日发送(条)": "昨日发送(条)",
    "一周发送(条)": "一周发送(条)",
    "当月发送(条)": "当月发送(条)",
    "日": "日",
    "昨日": "昨日",
    "周": "周",
    "月": "月",
    "关闭所有": "关闭所有",
    "关闭左侧": "关闭左侧",
    "关闭右侧": "关闭右侧",
    "关闭其他": "关闭其他",
    "系统配置": "系统配置",
    "简约白": "简约白",
    "商务黑": "商务黑",
    "新增": "新增",
    "删除": "删除",
    "批量删除": "批量删除",
    "编辑": "编辑",
    "搜索": "搜索",
    "查询": "查询",
    "导出": "导出",
    "导入": "导入",
    "刷新": "刷新",
    "重置": "重置",
    "操作": "操作",
    "保存配置": "保存配置",
    "取消": "取消",
    "确定": "确定",
    "提交": "提交",
    "详情": "详情",
    "复制": "复制",
    "保存": "保存",
    "返回": "返回",
    "发送": "发送",
    "补发": "补发",
    "下载": "下载",
    "上传": "上传",
    "下一步": "下一步",
    "上一步": "上一步",
    "完成": "完成",
    "拒绝": "拒绝",
    "通过": "通过",
    "开启": "开启",
    "关闭": "关闭",
    "展开搜索框": "展开搜索框",
    "充值": "充值",
    "回款": "回款",
    "开通新产品": "开通新产品",
    "配置": "配置",
    "确认配置": "确认配置",
    "调价": "调价",
    "创建时间": "创建时间",
    "配置列": "配置列",
    "全部状态": "全部状态",
    "请选择": "请选择",
    "请输入": "请输入",
    "备注": "备注",
    "元": "元",
    "单价": "单价",
    "条": "条",
    "秒": "秒",
    "分": "分",
    "时": "时",
    "移入": "移入",
    "移出": "移出",
    "开始时间": "开始时间",
    "结束时间": "结束时间",
    "开通数": "开通数",
    "销售成本价": "销售成本价",
    "关键字策略": "关键字策略",
    "发送时间段": "发送时间段",
    "频次": "频次",
    "账号": "账号",
    "用户账号": "用户账号",
    "客户账号": "客户账号",
    "应用编号": "应用编号",
    "健康状态": "健康状态",
    "通道": "通道",
    "包含通道": "包含通道",
    "通道池": "通道池",
    "通道池名称": "通道池名称",
    "通道池类型": "通道池类型",
    "通道编号": "通道编号",
    "账号状态": "账号状态",
    "产品名称": "产品名称",
    "用户类型": "用户类型",
    "付费类型": "付费类型",
    "计费类型": "计费类型",
    "计费方式": "计费方式",
    "付费方式": "付费方式",
    "公司": "公司",
    "公司名称": "公司名称",
    "客户类型": "客户类型",
    "账户余额": "账户余额",
    "消费金额": "消费金额",
    "销售": "销售",
    "售价": "售价",
    "运营商": "运营商",
    "发送优先级": "发送优先级",
    "子号": "子号",
    "用户子号": "用户子号",
    "启用状态": "启用状态",
    "产品状态": "产品状态",
    "产品类型": "产品类型",
    "指定扩展号": "指定扩展号",
    "强制审核": "强制审核",
    "黑名单策略": "黑名单策略",
    "关键字匹配策略": "关键字匹配策略",
    "发送时间限制": "发送时间限制",
    "频限": "频限",
    "补发策略": "补发策略",
    "开始补发时间": "开始补发时间",
    "结束补发时间": "结束补发时间",
    "最大补发时间": "最大补发时间",
    "补发通道池": "补发通道池",
    "补发模式": "补发模式",
    "补发错误码": "补发错误码",
    "接口类型": "接口类型",
    "状态": "状态",
    "绑定IP": "绑定IP",
    "连接数": "连接数",
    "最大速度": "最大速度",
    "回执推送方式": "回执推送方式",
    "回执推送地址": "回执推送地址",
    "上行推送方式": "上行推送方式",
    "上行推送地址": "上行推送地址",
    "通道池ID": "通道池ID",
    "供应商": "供应商",
    "基本配置": "基本配置",
    "安全参数配置": "安全参数配置",
    "接口参数配置": "接口参数配置",
    "通道池配置": "通道池配置",
    "复制接口参数": "复制接口参数",
    "注意：请仔细检查配置项后再点击保存配置！": "注意：请仔细检查配置项后再点击保存配置！",
    "策略名称": "策略名称",
    "补发策略名称": "补发策略名称",
    "补发时间段": "补发时间段",
    "添加补发错误码": "添加补发错误码",
    "通道名称": "通道名称",
    "接入协议": "接入协议",
    "接入号码": "接入号码",
    "速率": "速率",
    "通道待发量": "通道待发量",
    "支持省份": "支持省份",
    "服务器IP": "服务器IP",
    "端口": "端口",
    "企业代码": "企业代码",
    "业务代码": "业务代码",
    "是否含税": "是否含税",
    "落地省网": "落地省网",
    "下发总速度": "下发总速度",
    "通道并发数": "通道并发数",
    "省内价格": "省内价格",
    "省外价格": "省外价格",
    "计费字数": "计费字数",
    "单条计费字数": "单条计费字数",
    "上行匹配模式": "上行匹配模式",
    "配置通道池": "配置通道池",
    "可配置通道": "可配置通道",
    "权重": "权重",
    "产品编号": "产品编号",
    "接收手机": "接收手机",
    "拦截原因": "拦截原因",
    "签名": "签名",
    "内容": "内容",
    "发送内容": "发送内容",
    "短信内容": "短信内容",
    "发送号码": "发送号码",
    "归属地": "归属地",
    "回复时间": "回复时间",
    "推送次数": "推送次数",
    "回复内容": "回复内容",
    "计费条数": "计费条数",
    "接收状态": "接收状态",
    "短信组ID": "短信组ID",
    "短信ID": "短信ID",
    "发送状态": "发送状态",
    "响应代码": "响应代码",
    "推送回执": "推送回执",
    "回执时间": "回执时间",
    "完整耗时": "完整耗时",
    "推送上行": "推送上行",
    "类型": "类型",
    "匹配用户": "匹配用户",
    "创建人": "创建人",
    "转移": "转移",
    "定时刷新": "定时刷新",
    "开始": "开始",
    "停止": "停止",
    "选中批量": "选中批量",
    "普通模式": "普通模式",
    "智能模式": "智能模式",
    "发送人": "发送人",
    "号码个数": "号码个数",
    "序号": "序号",
    "审核结果": "审核结果",
    "审核人": "审核人",
    "审批时间": "审批时间",
    "关键字列表": "关键字列表",
    "关键字类型": "关键字类型",
    "所属用户": "所属用户",
    "内容关键字": "内容关键字",
    "添加人": "添加人",
    "添加时间": "添加时间",
    "级别": "级别",
    "方向": "方向",
    "顺序": "顺序",
    "新增用户": "新增用户",
    "编辑用户": "编辑用户",
    "新增补发策略": "新增补发策略",
    "编辑补发策略": "编辑补发策略",
    "编辑标准协议通道": "编辑标准协议通道",
    "新增通道池": "新增通道池",
    "编辑通道池": "编辑通道池",
    "加入黑名单": "加入黑名单",
    "新增关键字": "新增关键字",
    "编辑关键字": "编辑关键字",
    "新增关键字类型": "新增关键字类型",
    "编辑关键字类型": "编辑关键字类型",
    "新增关键字策略": "新增关键字策略",
    "编辑关键字策略": "编辑关键字策略",
    "拖拽进行排序": "拖拽进行排序",
    "模板内容": "模板内容",
    "所有关键字类型": "所有关键字类型",
    "策略所含关键字类型": "策略所含关键字类型",
    "tip:关键字类型的先后加入顺序决定在该策略中的优先级": "tip:关键字类型的先后加入顺序决定在该策略中的优先级",
    "新增标准协议通道": "新增标准协议通道",
    "新增关键字匹配策略": "新增关键字匹配策略",
    "测试": "测试",
    "新增企业": "新增企业",
    "新增企业组": "新增企业组",
    "新建节点": "新建节点",
    "编辑节点": "编辑节点",
    "删除节点": "删除节点",
    "导出数据": "导出数据",
    "允许": "允许",
    "不允许": "不允许",
    "设置密码": "设置密码",
    "点击生成随机密码": "点击生成随机密码",
    "已开通产品": "已开通产品",
    "产品列表": "产品列表",
    "确认开通": "确认开通",
    "注意：请仔细检查配置项后再点击保存配置！": "注意：请仔细检查配置项后再点击保存配置！",
    "点击返回（或按下esc）": "点击返回（或按下esc）",
    "四级": "四级",
    "三级": "三级",
    "二级": "二级",
    "扩展号": "扩展号",
    "用户扩展码模式": "用户扩展码模式",
    "复制接口参数": "复制接口参数",
    "注：输入IP段格式【192.168.0.0/24】": "注：输入IP段格式【192.168.0.0/24】",
    "启用": "启用",
    "停用": "停用",
    "产品调价": "产品调价",
    "您正在删除此用户，要继续吗?": "您正在删除此用户，要继续吗?",
    "提示": "提示",
    "继续": "继续",
    "您正在删除此用户下的产品，要继续吗?": "您正在删除此用户下的产品，要继续吗?",
    "取消删除": "取消删除",
    "请确认通道池配置": "请确认通道池配置",
    "接口参数配置<回执推送地址>未填写": "接口参数配置<回执推送地址>未填写",
    "接口参数配置<上行推送地址>未填写": "接口参数配置<上行推送地址>未填写",
    "请选择补发时间段": "请选择补发时间段",
    "业务类型": "业务类型",
    "对接协议": "对接协议",
    "接入号": "接入号",
    "链接数": "链接数",
    "复制成功": "复制成功",
    "一天": "一天",
    "三天": "三天",
    "价格": "价格",
    "所含通道": "所含通道",
    "取消挂载": "取消挂载",
    "操作流程:<br/>1.点击挂载通道池<br/>2.点击筛选,查询相应的通道池<br/>3.点击要挂载通道池的“确认挂载”按钮,进行挂载<br/>4.被挂载的通道池添加到当前通道列表中": "操作流程:<br/>1.点击挂载通道池<br/>2.点击筛选,查询相应的通道池<br/>3.点击要挂载通道池的“确认挂载”按钮,进行挂载<br/>4.被挂载的通道池添加到当前通道列表中",
    "小时": "小时",
    "要继续吗?": "要继续吗?",
    "您正在删除此策略，要继续吗?": "您正在删除此策略，要继续吗?",
    "标准协议通道": "标准协议通道",
    "当": "当",
    "最": "最",
    "选择上方运营商生成通道编号": "选择上方运营商生成通道编号",
    "付费号码": "付费号码",
    "请输入下发速度1-10000": "请输入下发速度1-10000",
    "导入": "导入",
    "导出": "导出",
    "通道转移": "通道转移",
    "当前通道": "当前通道",
    "目标通道": "目标通道",
    "当前通道正在使用，禁止删除！": "当前通道正在使用，禁止删除！",
    "您已进入通道测试模式": "您已进入通道测试模式",
    "导出成功，在新增标准协议通道导入使用": "导出成功，在新增标准协议通道导入使用",
    "导入成功": "导入成功",
    "启用中": "启用中",
    "运营号段": "运营号段",
    "加入": "加入",
    "当前通道池正在使用，禁止删除！": "当前通道池正在使用，禁止删除！",
    "通道池中已包含该通道！！！": "通道池中已包含该通道！！！",
    "当前为通道编号": "当前为通道编号",
    "的短信发送测试模式，点击右侧关闭测试模式": "的短信发送测试模式，点击右侧关闭测试模式",
    "文件上传": "文件上传",
    "点击上传文件": "点击上传文件",
    "号码文件支持txt、xls、xlsx格式文件,只需要在第一列填写号码即可(txt文件每行1个号码),最多输入10万个号码": "号码文件支持txt、xls、xlsx格式文件,只需要在第一列填写号码即可(txt文件每行1个号码),最多输入10万个号码",
    "当前输入": "当前输入",
    "个号码": "个号码",
    "个字": "个字",
    "短信签名": "短信签名",
    "70个字以内算1条,超过70个字按每条67个字拆分，共拆分": "70个字以内算1条,超过70个字按每条67个字拆分，共拆分",
    "扩展": "扩展",
    "自定义扩展号": "自定义扩展号",
    "缺少应用id！": "缺少应用id！",
    "缺少短信内容！": "缺少短信内容！",
    "缺少手机号！": "缺少手机号！",
    "缺少必要参数！": "缺少必要参数！",
    "已关闭测试模式": "已关闭测试模式",
    "开始时间不能大于结束时间！": "开始时间不能大于结束时间！",
    "提交时间": "提交时间",
    "删除选中": "删除选中",
    "新增黑白名单": "新增黑白名单",
    "所属签名": "所属签名",
    "编辑黑白名单": "编辑黑白名单",
    "秒后刷新": "秒后刷新",
    "提取关键字": "提取关键字",
    "通道池转移": "通道池转移",
    "目标通道池": "目标通道池",
    "健康": "健康",
    "不健康": "不健康",
    "最短间隔10秒刷新": "最短间隔10秒刷新",
    "返还类型": "返还类型",
    "时间范围": "时间范围",
    "回账": "回账",
    "您正在删除此企业组，要继续吗?": "您正在删除此企业组，要继续吗?",
    "您正在删除此企业，要继续吗?": "您正在删除此企业，要继续吗?",
    "客户经理发送统计报表": "客户经理发送统计报表",
    "通道发送统计报表": "通道发送统计报表",
    "客户发送统计报表": "客户发送统计报表",
    "按天发送统计报表": "按天发送统计报表",
    "错误码统计报表": "错误码统计报表",
    "利润统计报表": "利润统计报表",
    "还原": "还原",
    "省份合计统计": "省份合计统计",
    "单省份日期统计": "单省份日期统计",
    "分省统计报表": "分省统计报表",
    "0-5秒": "0-5秒",
    "6-7秒": "6-7秒",
    "8-10秒": "8-10秒",
    "11秒以上": "11秒以上",
    "发送速度统计报表": "发送速度统计报表",
    "签名统计报表": "签名统计报表",
    "新增号段": "新增号段",
    "号码段": "号码段",
    "移动": "移动",
    "联通": "联通",
    "电信": "电信",
    "回车为一项输入": "回车为一项输入",
    "应用付费方式": "应用付费方式",
    "平均每日发送": "平均每日发送",
    "发送结果": "发送结果",
    "天": "天",
    "年": "年",
    "当前角色": "当前角色",
    "切换为": "切换为",
    "个人信息": "个人信息",
    "登 出": "登 出",
    "删除成功": "删除成功",
    "关键字": "关键字",
    "关键字内容": "关键字内容",
    "关键字匹配类型": "关键字匹配类型",
    "选择产品": "选择产品",
    "修改密码": "修改密码",
    "退出": "退出",
    "仪表盘": "仪表盘",
    "发送短信": "发送短信",
    "发送明细": "发送明细",
    "上行回复": "上行回复",
    "应用管理": "应用管理",
    "通讯录": "通讯录",
    "旧密码": "旧密码",
    "至少6位字符": "至少6位字符",
    "新密码": "新密码",
    "不用了": "不用了",
    "密码格式不正确": "密码格式不正确",
    "请输入密码": "请输入密码",
    "新旧密码不能相同": "新旧密码不能相同",
    "用户短信发送平台": "用户短信发送平台",
    "验证码": "验证码",
    "改变主题": "改变主题",
    "注册": "注册",
    "字数统计": "字数统计",
    "号码文件": "号码文件",
    "点击上传号码文件": "点击上传号码文件",
    "子码": "子码",
    "切换系统语言": "切换系统语言",
    "定时发送": "定时发送",
    "选择时间": "选择时间",
    "立即发送": "立即发送",
    "请选择时间": "请选择时间",
    "统计管理": "统计管理",
    "不选择时间则立即发送": "不选择时间则立即发送",
    "缺少必填项！": "缺少必填项！",
    "加载中": "加载中",
    "下载示例文件": "下载示例文件",
    "导入联系人": "导入联系人",
    "示例文件": "示例文件",
    "客户发送统计": "客户发送统计",
    "错误码统计": "错误码统计",
    "签名发送统计": "签名发送统计",
    "上传成功": "上传成功",
    "上传失败": "上传失败",
    "下载完成": "下载完成",
    "号码文件支持xls、xlsx格式文件,只需要在第一列填写号码即可,最多输入10万个号码": "号码文件支持xls、xlsx格式文件,只需要在第一列填写号码即可,最多输入10万个号码",
    "当前用户": "当前用户",
    "必选": "必选",
    "必填": "必填",
    "用户管理": "用户管理",
    "昵称": "昵称",
    "注册用户": "注册用户",
    "用户角色": "用户角色",
    "角色": "角色",
    "发送时间": "发送时间",
    "通讯录名称": "通讯录名称",
    "分组个数": "分组个数",
    "联系人个数": "联系人个数",
    "添加分组": "添加分组",
    "导出分组": "导出分组",
    "添加联系人": "添加联系人",
    "分组": "分组",
    "分组信息": "分组信息",
    "下载示例文件": "下载示例文件",
    "导入联系人": "导入联系人",
    "确认编辑": "确认编辑",
    "分组名称": "分组名称",
    "分组类型": "分组类型",
    "联系人信息": "联系人信息",
    "姓名": "姓名",
    "首字母": "首字母",
    "昵称": "昵称",
    "传真号码": "传真号码",
    "邮箱地址": "邮箱地址",
    "生日": "生日",
    "公司名称": "公司名称",
    "联系地址": "联系地址",
    "编辑并保存": "编辑并保存",
    "通讯录发送": "通讯录发送",
    "名称": "名称",
    "请先清空手机号码": "请先清空手机号码",
}