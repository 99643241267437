<template>
<el-config-provider :locale="locale">
  <router-view />
</el-config-provider>
</template>
<script setup>
import { onMounted ,ref,computed} from "vue";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import zhTw from 'element-plus/dist/locale/zh-tw.mjs'
import { emitter } from "@/utils/bus.js";


const language =
  localStorage.getItem("lang") ||
  (navigator.language || "en").toLocaleLowerCase().split("-")[0];
const locale = ref(null);


const changeLang = (lang) => {
  
  switch (lang) {
    case "zh":
      locale.value = zhCn;
      break;
    case "en":
      locale.value = en;
      break;
    case "tw":
      locale.value = zhTw;
      break;
    default:
      break;
  }
};

emitter.on("changeLang", changeLang);
changeLang(language);

</script>
<style lang="scss"></style>
