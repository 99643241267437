export default {
    logo: '云信通短信平台',
    menu: {
        authName44: '用户与产品管理',
        authName48: '用户列表',
        authName46: '产品列表',
        authName47: '产品树',
        authName45: '补发策略',
        authName49: '通道管理',
        authName50: '通道列表',
        authName51: '通道池列表',
        authName62: '短信发送',
        authName35: '短信管理',
        authName37: '拦截记录',
        authName38: '上行回复',
        authName36: '发送明细',
        authName39: '短信安全',
        authName40: '黑白名单',
        authName43: '短信审核',
        authName42: '审核日志',
        authName41: '关键字管理',
        authName65: '财务管理',
        authName67: '客户充值记录',
        authName68: '客户调价记录',
        authName81: '通道调价记录',
        authName84: '客户回款记录',
        authName94: '归档返还记录',
        authName58: '企业管理',
        authName60: '企业',
        authName59: '企业组',
        authName52: '统计管理',
        authName73: '客户发送统计',
        authName74: '通道发送统计',
        authName75: '客户经理统计',
        authName76: '按天统计',
        authName77: '分省统计',
        authName78: '错误码统计',
        authName79: '签名统计',
        authName80: '发送速度统计',
        authName85: '利润统计',
        authName63: '监控服务',
        authName82: '通道监控',
        authName83: '用户发送监控',
        authName69: '系统管理',
        authName70: '号码地区',
        authName71: '退订关键词',
        authName93: '错误码对照表',
        authName86: '告警管理',
        authName91: '联系人',
        authName90: '告警场景',
        authName88: '活跃告警',
        authName92: '通知组',
        authName89: '历史告警',
        authName87: '告警规则',
        authName1: '仪表盘',
        authName3: '超级管理员',
        authName19: '字典详情',
        authName4: '角色管理',
        authName5: '菜单管理',
        authName6: 'api管理',
        authName7: '用户管理',
        authName18: '字典管理',
        authName20: '操作历史',
        authName8: '个人信息',
        authName14: '系统工具',
        authName25: '自动化代码（复用）',
        authName26: '自动化package',
        authName15: '代码生成器',
        authName24: '自动化代码管理',
        authName16: '表单生成器',
        authName17: '系统配置',
        authName27: '自动化插件模板',
        authName9: '示例文件',
        authName10: 'excel导入导出',
        authName11: '媒体库（上传下载）',
        authName12: '断点续传',
        authName13: '客户列表（资源示例）',
        authName2: '关于我们',
        authName22: '官方网站',
        authName23: '服务器状态',
    },
    history: {
        "404": "迷路了*。*",
        "userAndproductManage": "用户与产品管理",
        "channelMgt": "通道管理",
        "smsSend": "短信发送",
        "smsMgt": "短信管理",
        "smsSecurity": "短信安全",
        "financialManage": "财务管理",
        "enterpriseManage": "企业管理",
        "statisticalManage": "统计管理",
        "monitorService": "监控服务",
        "systemManage": "系统管理",
        "alertManage": "告警管理",
        "dashboard": "仪表盘",
        "superAdmin": "超级管理员",
        "person": "个人信息",
        "systemTools": "系统工具",
        "example": "示例文件",
        "about": "关于我们",
        "https://www.gin-vue-admin.com": "官方网站",
        "state": "服务器状态",
        "Reload": "",
        // =======
        "userList": "用户列表",
        "productList": "产品列表",
        "productTree": "产品树",
        "reissue": "补发策略",
        "channelList": "通道列表",
        "channelPoolList": "通道池列表",
        "smsSend": "短信发送",
        "interceptLog": "拦截记录",
        "smsUpLog": "上行回复",
        "sendDetail": "发送明细",
        "blackWhiteList": "黑白名单",
        "auditSms": "短信审核",
        "auditLog": "审核日志",
        "keyword": "关键字管理",
        "rechargeRecord": "客户充值记录",
        "modifyPriceLog": "客户调价记录",
        "channelModifyPriceRecord": "通道调价记录",
        "customerRepaymentRecord": "客户回款记录",
        "customerCashBackRecord": "归档返还记录",
        "enterpriseInfo": "企业",
        "enterpriseGroupInfo": "企业组",
        "customerSend": "客户发送统计",
        "channelSend": "通道发送统计",
        "accountManager": "客户经理统计",
        "dayStatistics": "按天统计",
        "provinceStatistics": "分省统计",
        "errorCodeStatistics": "错误码统计",
        "signature": "签名统计",
        "sendSpeed": "发送速度统计",
        "profit": "利润统计",
        "channelMonitoring": "通道监控",
        "userSendMonitoring": "用户发送监控",
        "numberRegion": "号码地区",
        "unsubscribeKeywords": "退订关键词",
        "errReportCode": "错误码对照表",
        "contactPerson": "联系人",
        "instance": "告警场景",
        "active": "活跃告警",
        "notificationGroup": "通知组",
        "history": "历史告警",
        "rules": "告警规则",
        "dashboard": "仪表盘",
        "authority": "角色管理",
        "menu": "菜单管理",
        "api": "api管理",
        "user": "用户管理",
        "dictionary": "字典管理",
        "operation": "操作历史",
        "autoPkg": "自动化package",
        "autoCode": "代码生成器",
        "autoCodeAdmin": "自动化代码管理",
        "formCreate": "表单生成器",
        "system": "系统配置",
        "autoPlug": "自动化插件模板",
        "excel": "excel导入导出",
        "upload": "媒体库（上传下载）",
        "breakpoint": "断点续传",
        "customer": "客户列表（资源示例）",
        "about": "关于我们",
        "https://www.gin-vue-admin.com": "官方网站",
        "state": "服务器状态"
    },
    "云信通短信平台": "雲信通短信平台",
    "关键字类型名": "關鍵字類型名",
    "关键字策略名称": "關鍵字策略名稱",
    "手机号": "手機號",
    "充值前余额": "充值前餘額",
    "充值金额": "充值金額",
    "充值后余额": "充值後餘額",
    "充值类型": "充值類型",
    "充值方式": "充值方式",
    "操作人": "操作人",
    "充值时间": "充值時間",
    "确认回账时间": "確認回賬時間",
    "调整前单价": "調整前單價",
    "调整后单价": "調整後單價",
    "调整时间": "調整時間",
    "生效时间": "生效時間",
    "生效状态": "生效狀態",
    "通道名": "通道名",
    "省内": "省內",
    "省外": "省外",
    "回款前余额": "回款前餘額",
    "回款金额": "回款金額",
    "回款后余额": "回款後餘額",
    "回款方式": "回款方式",
    "回款时间": "回款時間",
    "归档用户": "歸檔用戶",
    "归档金额": "歸檔金額",
    "归档类型": "歸檔類型",
    "归档时间": "歸檔時間",
    "操作时间": "操作時間",
    "企业名称": "企業名稱",
    "所属企业组": "所屬企業組",
    "联系人": "聯繫人",
    "联系电话": "聯繫電話",
    "企业全称": "企業全稱",
    "企业简称": "企業簡稱",
    "邮箱": "郵箱",
    "企业组名称": "企業組名稱",
    "发送总量": "發送總量",
    "未提交": "未提交",
    "拦截": "攔截",
    "黑名单": "黑名單",
    "审核失败": "審核失敗",
    "发送成功": "發送成功",
    "发送失败": "發送失敗",
    "未知状态": "未知狀態",
    "未知率": "未知率",
    "成功率": "成功率",
    "协议类型": "協議類型",
    "总提交": "總提交",
    "成功": "成功",
    "失败": "失敗",
    "未知": "未知",
    "客户经理": "客戶經理",
    "统计时间": "統計時間",
    "提交成功率": "提交成功率",
    "网关成功率": "網關成功率",
    "日期": "日期",
    "表格": "表格",
    "图表": "圖表",
    "失败回执": "失敗回執",
    "发送数量": "發送數量",
    "时间段": "時間段",
    "错误码": "錯誤碼",
    "发送量": "發送量",
    "数量": "數量",
    "占比": "佔比",
    "总计": "總計",
    "应用价格": "應用價格",
    "应用计费类型": "應用計費類型",
    "通道价格": "通道價格",
    "通道计费类型": "通道計費類型",
    "应用付费类型": "應用付費類型",
    "应用所属企业": "應用所屬企業",
    "应用所属销售": "應用所屬銷售",
    "应收金额": "應收金額",
    "实际成本": "實際成本",
    "利润": "利潤",
    "号段": "號段",
    "所属省": "所屬省",
    "所属市": "所屬市",
    "包含词": "包含詞",
    "含义": "含義",
    "提成比例": "提成比例",
    "是否允许登录": "是否允許登錄",
    "所属销售": "所屬銷售",
    "所属企业": "所屬企業",
    "短信发送平台": "短信發送平台",
    "登录": "登錄",
    "清空": "清空",
    "用户名": "用戶名",
    "密码": "密碼",
    "请正确填写登录信息": "請正確填寫登錄信息",
    "请输入正确的用户名": "請輸入正確的用戶名",
    "请输入正确的密码": "請輸入正確的密碼",
    "请输入验证码": "請輸入驗證碼",
    "请输入账号": "請輸入賬號",
    "请输入密码": "請輸入密碼",
    "验证码格式不正确": "驗證碼格式不正確",
    "登录成功": "登錄成功",
    "登录中，请稍后...": "登錄中，請稍後...",
    "已配置数据库信息，无法初始化": "已配置數據庫信息，無法初始化",
    "当前温度": "當前溫度",
    "更新时间": "更新時間",
    "月发送量": "月發送量",
    "上午好": "上午好",
    "请开始一天的工作吧": "請開始一天的工作吧",
    "中午好": "中午好",
    "记得吃午饭哦": "記得吃午飯哦",
    "下午好": "下午好",
    "长时间工作要注意短暂休息哦": "長時間工作要注意短暫休息哦",
    "晚上好": "晚上好",
    "加油！你是最棒哒！": "加油！你是最棒噠！",
    "夜深了": "夜深了",
    "注意休息哦": "注意休息哦",
    "统计": "統計",
    "待审核(条)": "待審核(條)",
    "待发(条)": "待發(條)",
    "当日发送(条)": "當日發送(條)",
    "昨日发送(条)": "昨日發送(條)",
    "一周发送(条)": "一周發送(條)",
    "当月发送(条)": "當月發送(條)",
    "日": "日",
    "昨日": "昨日",
    "周": "週",
    "月": "月",
    "关闭所有": "關閉所有",
    "关闭左侧": "關閉左側",
    "关闭右侧": "關閉右側",
    "关闭其他": "關閉其他",
    "系统配置": "系統配置",
    "简约白": "簡約白",
    "商务黑": "商務黑",
    "新增": "新增",
    "删除": "刪除",
    "批量删除": "批量刪除",
    "编辑": "編輯",
    "搜索": "搜索",
    "查询": "查詢",
    "导出": "導出",
    "导入": "導入",
    "刷新": "刷新",
    "重置": "重置",
    "操作": "操作",
    "保存配置": "保存配置",
    "取消": "取消",
    "确定": "確定",
    "提交": "提交",
    "详情": "詳情",
    "复制": "複製",
    "保存": "保存",
    "返回": "返回",
    "发送": "發送",
    "补发": "補發",
    "下载": "下載",
    "上传": "上傳",
    "下一步": "下一步",
    "上一步": "上一步",
    "完成": "完成",
    "拒绝": "拒絕",
    "通过": "通過",
    "开启": "開啟",
    "关闭": "關閉",
    "展开搜索框": "展開搜索框",
    "充值": "充值",
    "回款": "回款",
    "开通新产品": "開通新產品",
    "配置": "配置",
    "确认配置": "確認配置",
    "调价": "調價",
    "创建时间": "創建時間",
    "配置列": "配置列",
    "全部状态": "全部狀態",
    "请选择": "請選擇",
    "请输入": "請輸入",
    "备注": "備註",
    "元": "元",
    "单价": "單價",
    "条": "條",
    "秒": "秒",
    "分": "分",
    "时": "時",
    "移入": "移入",
    "移出": "移出",
    "开始时间": "開始時間",
    "结束时间": "結束時間",
    "开通数": "開通數",
    "销售成本价": "銷售成本價",
    "关键字策略": "關鍵字策略",
    "发送时间段": "發送時間段",
    "频次": "頻次",
    "账号": "賬號",
    "用户账号": "用戶賬號",
    "客户账号": "客戶賬號",
    "应用编号": "應用編號",
    "健康状态": "健康狀態",
    "通道": "通道",
    "包含通道": "包含通道",
    "通道池": "通道池",
    "通道池名称": "通道池名稱",
    "通道池类型": "通道池類型",
    "通道编号": "通道編號",
    "账号状态": "賬號狀態",
    "产品名称": "產品名稱",
    "用户类型": "用戶類型",
    "付费类型": "付費類型",
    "计费类型": "計費類型",
    "计费方式": "計費方式",
    "付费方式": "付費方式",
    "公司": "公司",
    "公司名称": "公司名稱",
    "客户类型": "客戶類型",
    "账户余额": "賬戶餘額",
    "消费金额": "消費金額",
    "销售": "銷售",
    "售价": "售價",
    "运营商": "運營商",
    "发送优先级": "發送優先級",
    "子号": "子號",
    "用户子号": "用戶子號",
    "启用状态": "啟用狀態",
    "产品状态": "產品狀態",
    "产品类型": "產品類型",
    "指定扩展号": "指定擴展號",
    "强制审核": "強制審核",
    "黑名单策略": "黑名單策略",
    "关键字匹配策略": "關鍵字匹配策略",
    "发送时间限制": "發送時間限制",
    "频限": "頻限",
    "补发策略": "補發策略",
    "开始补发时间": "開始補發時間",
    "结束补发时间": "結束補發時間",
    "最大补发时间": "最大補發時間",
    "补发通道池": "補發通道池",
    "补发模式": "補發模式",
    "补发错误码": "補發錯誤碼",
    "接口类型": "接口類型",
    "状态": "狀態",
    "绑定IP": "綁定IP",
    "连接数": "連接數",
    "最大速度": "最大速度",
    "回执推送方式": "回執推送方式",
    "回执推送地址": "回執推送地址",
    "上行推送方式": "上行推送方式",
    "上行推送地址": "上行推送地址",
    "通道池ID": "通道池ID",
    "供应商": "供應商",
    "基本配置": "基本配置",
    "安全参数配置": "安全參數配置",
    "接口参数配置": "接口參數配置",
    "通道池配置": "通道池配置",
    "复制接口参数": "複製接口參數",
    "注意：请仔细检查配置项后再点击保存配置！": "注意：請仔細檢查配置項後再點擊保存配置！",
    "策略名称": "策略名稱",
    "补发策略名称": "補發策略名稱",
    "补发时间段": "補發時間段",
    "添加补发错误码": "添加補發錯誤碼",
    "通道名称": "通道名稱",
    "接入协议": "接入協議",
    "接入号码": "接入號碼",
    "速率": "速率",
    "通道待发量": "通道待發量",
    "支持省份": "支持省份",
    "服务器IP": "服務器IP",
    "端口": "端口",
    "企业代码": "企業代碼",
    "业务代码": "業務代碼",
    "是否含税": "是否含稅",
    "落地省网": "落地省網",
    "下发总速度": "下發總速度",
    "通道并发数": "通道並發數",
    "省内价格": "省內價格",
    "省外价格": "省外價格",
    "计费字数": "計費字數",
    "单条计费字数": "單條計費字數",
    "上行匹配模式": "上行匹配模式",
    "配置通道池": "配置通道池",
    "可配置通道": "可配置通道",
    "权重": "權重",
    "产品编号": "產品編號",
    "接收手机": "接收手機",
    "拦截原因": "攔截原因",
    "签名": "簽名",
    "内容": "內容",
    "发送内容": "發送內容",
    "短信内容": "短信內容",
    "发送号码": "發送號碼",
    "归属地": "歸屬地",
    "回复时间": "回复時間",
    "推送次数": "推送次數",
    "回复内容": "回复內容",
    "计费条数": "計費條數",
    "接收状态": "接收狀態",
    "短信组ID": "短信組ID",
    "短信ID": "短信ID",
    "发送状态": "發送狀態",
    "响应代码": "響應代碼",
    "推送回执": "推送回執",
    "回执时间": "回執時間",
    "完整耗时": "完整耗時",
    "推送上行": "推送上行",
    "类型": "類型",
    "匹配用户": "匹配用戶",
    "创建人": "創建人",
    "转移": "轉移",
    "定时刷新": "定時刷新",
    "开始": "開始",
    "停止": "停止",
    "选中批量": "選中批量",
    "普通模式": "普通模式",
    "智能模式": "智能模式",
    "发送人": "發送人",
    "号码个数": "號碼個數",
    "序号": "序號",
    "审核结果": "審核結果",
    "审核人": "審核人",
    "审批时间": "審批時間",
    "关键字列表": "關鍵字列表",
    "关键字类型": "關鍵字類型",
    "所属用户": "所屬用戶",
    "内容关键字": "內容關鍵字",
    "添加人": "添加人",
    "添加时间": "添加時間",
    "级别": "級別",
    "方向": "方向",
    "顺序": "順序",
    "新增用户": "新增用戶",
    "编辑用户": "編輯用戶",
    "新增补发策略": "新增補發策略",
    "编辑补发策略": "編輯補發策略",
    "编辑标准协议通道": "編輯標準協議通道",
    "新增通道池": "新增通道池",
    "编辑通道池": "編輯通道池",
    "加入黑名单": "加入黑名單",
    "新增关键字": "新增關鍵字",
    "编辑关键字": "編輯關鍵字",
    "新增关键字类型": "新增關鍵字類型",
    "编辑关键字类型": "編輯關鍵字類型",
    "新增关键字策略": "新增關鍵字策略",
    "编辑关键字策略": "編輯關鍵字策略",
    "拖拽进行排序": "拖拽進行排序",
    "模板内容": "模板內容",
    "所有关键字类型": "所有關鍵字類型",
    "策略所含关键字类型": "策略所含關鍵字類型",
    "tip:关键字类型的先后加入顺序决定在该策略中的优先级": "tip:關鍵字類型的先後加入順序決定在該策略中的優先級",
    "新增标准协议通道": "新增標準協議通道",
    "新增关键字匹配策略": "新增關鍵字匹配策略",
    "测试": "測試",
    "新增企业": "新增企業",
    "新增企业组": "新增企業組",
    "新建节点": "新建節點",
    "编辑节点": "編輯節點",
    "删除节点": "刪除節點",
    "导出数据": "導出數據",
    "允许": "允許",
    "不允许": "不允許",
    "设置密码": "設置密碼",
    "点击生成随机密码": "點擊生成隨機密碼",
    "已开通产品": "已開通產品",
    "产品列表": "產品列表",
    "确认开通": "確認開通",
    "点击返回（或按下esc）": "點擊返回（或按下esc）",
    "四级": "四級",
    "三级": "三級",
    "二级": "二級",
    "扩展号": "擴展號",
    "用户扩展码模式": "用戶擴展碼模式",
    "注：输入IP段格式【192.168.0.0/24】": "注：輸入IP段格式【192.168.0.0/24】",
    "启用": "啟用",
    "停用": "停用",
    "产品调价": "產品調價",
    "您正在删除此用户，要继续吗?": "您正在刪除此用戶，要繼續嗎?",
    "提示": "提示",
    "继续": "繼續",
    "您正在删除此用户下的产品，要继续吗?": "您正在刪除此用戶下的產品，要繼續嗎?",
    "取消删除": "取消刪除",
    "请确认通道池配置": "請確認通道池配置",
    "接口参数配置<回执推送地址>未填写": "接口參數配置<回執推送地址>未填寫",
    "接口参数配置<上行推送地址>未填写": "接口參數配置<上行推送地址>未填寫",
    "请选择补发时间段": "請選擇補發時間段",
    "业务类型": "業務類型",
    "对接协议": "對接協議",
    "接入号": "接入號",
    "链接数": "鏈接數",
    "复制成功": "複製成功",
    "一天": "一天",
    "三天": "三天",
    "价格": "價格",
    "所含通道": "所含通道",
    "取消挂载": "取消掛載",
    "操作流程:<br/>1.点击挂载通道池<br/>2.点击筛选,查询相应的通道池<br/>3.点击要挂载通道池的“确认挂载”按钮,进行挂载<br/>4.被挂载的通道池添加到当前通道列表中": "操作流程:<br/>1.點擊掛載通道池<br/>2.點擊篩選,查詢相應的通道池<br/>3.點擊要掛載通道池的“確認掛載”按鈕,進行掛載<br/>4.被掛載的通道池添加到當前通道列表中",
    "小时": "小時",
    "要继续吗?": "要繼續嗎?",
    "您正在删除此策略，要继续吗?": "您正在刪除此策略，要繼續嗎?",
    "标准协议通道": "標準協議通道",
    "当": "當",
    "最": "最",
    "选择上方运营商生成通道编号": "選擇上方運營商生成通道編號",
    "付费号码": "付費號碼",
    "请输入下发速度1-10000": "請輸入下發速度1-10000",
    "通道转移": "通道轉移",
    "当前通道": "當前通道",
    "目标通道": "目標通道",
    "当前通道正在使用，禁止删除！": "當前通道正在使用，禁止刪除！",
    "您已进入通道测试模式": "您已進入通道測試模式",
    "导出成功，在新增标准协议通道导入使用": "導出成功，在新增標準協議通道導入使用",
    "导入成功": "導入成功",
    "启用中": "啟用中",
    "运营号段": "運營號段",
    "加入": "加入",
    "当前通道池正在使用，禁止删除！": "當前通道池正在使用，禁止刪除！",
    "通道池中已包含该通道！！！": "通道池中已包含該通道！！！",
    "当前为通道编号": "當前為通道編號",
    "的短信发送测试模式，点击右侧关闭测试模式": "的短信發送測試模式，點擊右側關閉測試模式",
    "文件上传": "文件上傳",
    "点击上传文件": "點擊上傳文件",
    "号码文件支持txt、xls、xlsx格式文件,只需要在第一列填写号码即可(txt文件每行1个号码),最多输入10万个号码": "號碼文件支持txt、xls、xlsx格式文件,只需要在第一列填寫號碼即可(txt文件每行1個號碼),最多輸入10萬個號碼",
    "当前输入": "當前輸入",
    "个号码": "個號碼",
    "个字": "個字",
    "短信签名": "短信簽名",
    "70个字以内算1条,超过70个字按每条67个字拆分，共拆分": "70個字以內算1條,超過70個字按每條67個字拆分，共拆分",
    "扩展": "擴展",
    "自定义扩展号": "自定義擴展號",
    "缺少应用id！": "缺少應用id！",
    "缺少短信内容！": "缺少短信內容！",
    "缺少手机号！": "缺少手機號！",
    "缺少必填项！": "缺少必填項！",
    "缺少必要参数！": "缺少必要參數！",
    "已关闭测试模式": "已關閉測試模式",
    "开始时间不能大于结束时间！": "開始時間不能大於結束時間！",
    "提交时间": "提交時間",
    "删除选中": "刪除選中",
    "新增黑白名单": "新增黑白名單",
    "所属签名": "所屬簽名",
    "编辑黑白名单": "編輯黑白名單",
    "秒后刷新": "秒後刷新",
    "提取关键字": "提取關鍵字",
    "通道池转移": "通道池轉移",
    "目标通道池": "目標通道池",
    "健康": "健康",
    "不健康": "不健康",
    "最短间隔10秒刷新": "最短間隔10秒刷新",
    "返还类型": "返還類型",
    "时间范围": "時間範圍",
    "回账": "回賬",
    "您正在删除此企业组，要继续吗?": "您正在刪除此企業組，要繼續嗎?",
    "您正在删除此企业，要继续吗?": "您正在刪除此企業，要繼續嗎?",
    "客户经理发送统计报表": "客戶經理髮送統計報表",
    "通道发送统计报表": "通道發送統計報表",
    "客户发送统计报表": "客戶發送統計報表",
    "按天发送统计报表": "按天發送統計報表",
    "错误码统计报表": "錯誤碼統計報表",
    "利润统计报表": "利潤統計報表",
    "还原": "還原",
    "省份合计统计": "省份合計統計",
    "单省份日期统计": "單省份日期統計",
    "分省统计报表": "分省統計報表",
    "0-5秒": "0-5秒",
    "6-7秒": "6-7秒",
    "8-10秒": "8-10秒",
    "11秒以上": "11秒以上",
    "发送速度统计报表": "發送速度統計報表",
    "签名统计报表": "簽名統計報表",
    "新增号段": "新增號段",
    "号码段": "號碼段",
    "移动": "移動",
    "联通": "聯通",
    "电信": "電信",
    "回车为一项输入": "回車為一項輸入",
    "应用付费方式": "應用付費方式",
    "平均每日发送": "平均每日發送",
    "发送结果": "發送結果",
    "天": "天",
    "年": "年",
    "当前角色": "當前角色",
    "切换为": "切換為",
    "个人信息": "個人信息",
    "登 出": "登 出",
    "删除成功": "刪除成功",
    "关键字": "關鍵字",
    "关键字内容": "關鍵字內容",
    "关键字匹配类型": "關鍵字匹配類型",
    "选择产品": "選擇產品",
    "修改密码": "修改密碼",
    "退出": "退出",
    "仪表盘": "儀錶盤",
    "发送短信": "發送短信",
    "发送明细": "發送明細",
    "上行回复": "上行回复",
    "应用管理": "應用管理",
    "通讯录": "通訊錄",
    "旧密码": "舊密碼",
    "至少6位字符": "至少6位字符",
    "新密码": "新密碼",
    "不用了": "不用了",
    "密码格式不正确": "密碼格式不正確",
    "新旧密码不能相同": "新舊密碼不能相同",
    "用户短信发送平台": "用戶短信發送平台",
    "验证码": "驗證碼",
    "改变主题": "改變主題",
    "注册": "註冊",
    "字数统计": "字數統計",
    "号码文件": "號碼文件",
    "点击上传号码文件": "點擊上傳號碼文件",
    "子码": "子碼",
    "切换系统语言": "切換系統語言",
    "定时发送": "定時發送",
    "选择时间": "選擇時間",
    "立即发送": "立即發送",
    "请选择时间": "請選擇時間",
    "统计管理": "統計管理",
    "不选择时间则立即发送": "不選擇時間則立即發送",
    "缺少必填项！": "缺少必填項！",
    "加载中": "加載中",
    "下载示例文件": "下載示例文件",
    "导入联系人": "導入聯繫人",
    "示例文件": "示例文件",
    "客户发送统计": "客戶發送統計",
    "错误码统计": "錯誤碼統計",
    "签名发送统计": "簽名發送統計",
    "上传成功": "上傳成功",
    "上传失败": "上傳失敗",
    "下载完成": "下載完成",
    "号码文件支持xls、xlsx格式文件,只需要在第一列填写号码即可,最多输入10万个号码": "號碼文件支持xls、xlsx格式文件,只需要在第一列填寫號碼即可,最多輸入10萬個號碼",
    "当前用户": "當前用戶",
    "必选": "必選",
    "必填": "必填",
    "用户管理": "用戶管理",
    "昵称": "暱稱",
    "注册用户": "註冊用戶",
    "用户角色": "用戶角色",
    "角色": "角色",
    "发送时间": "發送時間",
    "通讯录名称": "通訊錄名稱",
    "分组个数": "分組個數",
    "联系人个数": "聯繫人個數",
    "添加分组": "添加分組",
    "导出分组": "導出分組",
    "添加联系人": "添加聯繫人",
    "分组": "分組",
    "分组信息": "分組信息",
    "下载示例文件": "下載示例文件",
    "导入联系人": "導入聯繫人",
    "确认编辑": "確認編輯",
    "分组名称": "分組名稱",
    "分组类型": "分組類型",
    "联系人信息": "聯繫人信息",
    "姓名": "姓名",
    "首字母": "首字母",
    "昵称": "暱稱",
    "传真号码": "傳真號碼",
    "邮箱地址": "郵箱地址",
    "生日": "生日",
    "公司名称": "公司名稱",
    "联系地址": "聯繫地址",
    "编辑并保存": "編輯並保存",
    "通讯录发送": "通訊錄發送",
    "名称": "名稱",
    "请先清空手机号码": "請先清空手機號碼",
}