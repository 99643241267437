import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'
import tw from './tw'

const messages = {
  en,
  zh,
  tw,
}
const language = (navigator.language || 'en').toLocaleLowerCase()
const i18n = createI18n({
  locale: localStorage.getItem('lang') || language.split('-')[0] || 'en', // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: 'en', // 设置备用语言
  messages, 
  legacy: false,
})

export default i18n