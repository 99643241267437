import { createStore } from "vuex";

export default createStore({
  state: {
    userInfo: JSON.parse(window.localStorage.getItem("userInfo")) || "",
    token: JSON.parse(window.localStorage.getItem("token")) || ""
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
      window.localStorage.setItem("userInfo", JSON.stringify(data));
    },
    setToken(state, data) {
      state.token = data;
      window.localStorage.setItem("token", JSON.stringify(data));
    },
    removeUserInfo(state, data) {
      state.userInfo = "";
      window.localStorage.removeItem("userInfo");
    },
    removeToken(state, data) {
      state.token = "";
      window.localStorage.removeItem("token");
    }
  }
});
