export default {
    logo: 'Yunxintong SMS platform',
    menu: {
        authName44: 'User and Product Management',
        authName48: 'User List',
        authName46: 'Product List',
        authName47: 'Product Tree',
        authName45: 'reissue strategy',
        authName49: 'channel management',
        authName50: 'channel list',
        authName51: 'channel pool list',
        authName62: 'SMS sending',
        authName35: 'SMS management',
        authName37: 'Intercept record',
        authName38: 'uplink reply',
        authName36: 'Send Details',
        authName39: 'SMS Security',
        authName40: 'Black and white list',
        authName43: 'SMS audit',
        authName42: 'audit log',
        authName41: 'keyword management',
        authName65: 'Financial Management',
        authName67: 'Customer recharge record',
        authName68: 'Customer price adjustment record',
        authName81: 'channel price adjustment record',
        authName84: 'Customer payment record',
        authName94: 'Archive return record',
        authName58: 'Enterprise Management',
        authName60: 'Enterprise',
        authName59: 'Enterprise group',
        authName52: 'Statistics Management',
        authName73: 'Customer sending statistics',
        authName74: 'channel sending statistics',
        authName75: 'Customer Manager Statistics',
        authName76: 'Statistics by day',
        authName77: 'Statistics by Province',
        authName78: 'error code statistics',
        authName79: 'signature statistics',
        authName80: 'Send speed statistics',
        authName85: 'Profit Statistics',
        authName63: 'monitoring service',
        authName82: 'channel monitoring',
        authName83: 'User sending monitoring',
        authName69: 'System Management',
        authName70: 'number area',
        authName71: 'Unsubscribe keywords',
        authName93: 'Error Code Comparison Table',
        authName86: 'alarm management',
        authName91: 'Contact',
        authName90: 'alarm scene',
        authName88: 'active alarm',
        authName92: 'notification group',
        authName89: 'historical alarm',
        authName87: 'alarm rules',
        authName1: 'dashboard',
        authName3: 'super administrator',
        authName19: 'dictionary details',
        authName4: 'role management',
        authName5: 'menu management',
        authName6: 'api management',
        authName7: 'User Management',
        authName18: 'dictionary management',
        authName20: 'operation history',
        authName8: 'personal information',
        authName14: 'system tool',
        authName25: 'Automation code (reuse)',
        authName26: 'Automation package',
        authName15: 'code generator',
        authName24: 'automated code management',
        authName16: 'Form Builder',
        authName17: 'system configuration',
        authName27: 'automation plugin template',
        authName9: 'sample file',
        authName10: 'excel import and export',
        authName11: 'Media library (upload and download)',
        authName12: 'Breakpoint resume',
        authName13: 'Customer list (resource example)',
        authName2: 'About us',
        authName22: 'official website',
        authName23: 'server status',
    },
    history: {
        "404": "Lost *.*",
        "userAndproductManage": "User and Product Management",
        "channelMgt": "Channel Manager",
        "smsSend": "SMS Send",
        "smsMgt": "SMS Management",
        "smsSecurity": "Sms Security",
        "financialManage": "Financial Management",
        "enterpriseManage": "Enterprise Management",
        "statisticalManage": "Statistical Management",
        "monitorService": "Monitor Service",
        "systemManage": "System Management",
        "alertManage": "Alert Management",
        "dashboard": "Dashboard",
        "superAdmin": "Super Administrator",
        "person": "Personal Information",
        "systemTools": "System Tools",
        "example": "Example File",
        "about": "About us",
        "https://www.gin-vue-admin.com": "Official website",
        "state": "Server State",
        "Reload": "",
        // =======
        "userList": "User List",
        "productList": "Product List",
        "productTree": "Product Tree",
        "reissue": "Reissue Strategy",
        "channelList": "Channel List",
        "channelPoolList": "Channel Pool List",
        "smsSend": "SMS Send",
        "interceptLog": "Intercept Log",
        "smsUpLog": "Uplink Reply",
        "sendDetail": "Send Details",
        "blackWhiteList": "Black and White List",
        "auditSms": "SMS Audit",
        "auditLog": "Audit Log",
        "keyword": "Keyword Management",
        "rechargeRecord": "Customer recharge record",
        "modifyPriceLog": "Customer price adjustment record",
        "channelModifyPriceRecord": "ChannelModifyPriceRecord",
        "customerRepaymentRecord": "Customer Repayment Record",
        "customerCashBackRecord": "Archive Return Record",
        "enterpriseInfo": "Enterprise",
        "enterpriseGroupInfo": "Enterprise Group",
        "customerSend": "Customer Send Statistics",
        "channelSend": "Channel send statistics",
        "accountManager": "Account Manager Statistics",
        "dayStatistics": "Statistics by day",
        "provinceStatistics": "Statistics by Province",
        "errorCodeStatistics": "Error Code Statistics",
        "signature": "Signature Statistics",
        "sendSpeed": "Send Speed Statistics",
        "profit": "Profit Statistics",
        "channelMonitoring": "Channel Monitoring",
        "userSendMonitoring": "User Send Monitoring",
        "numberRegion": "Number Region",
        "unsubscribeKeywords": "Unsubscribe Keywords",
        "errReportCode": "Error Code Comparison Table",
        "contactPerson": "Contact Person",
        "instance": "Alarm Scenario",
        "active": "Active Alert",
        "notificationGroup": "Notification Group",
        "history": "History Alarm",
        "rules": "Alert Rules",
        "dashboard": "Dashboard",
        "authority": "Role Management",
        "menu": "Menu Management",
        "api": "api management",
        "user": "User Management",
        "dictionary": "dictionary management",
        "operation": "Operation History",
        "autoPkg": "automation package",
        "autoCode": "Code Generator",
        "autoCodeAdmin": "Automatic code management",
        "formCreate": "Form Builder",
        "system": "System Configuration",
        "autoPlug": "Automation Plugin Template",
        "excel": "excel import and export",
        "upload": "Media library (upload and download)",
        "breakpoint": "Breakpoint resume",
        "customer": "Customer List (Example Resource)",
        "about": "About us",
        "https://www.gin-vue-admin.com": "Official website",
        "state": "Server State"
    },
    "云信通短信平台": "Cloud ICT SMS platform",
    "关键字类型名": "Keyword Type Name",
    "关键字策略名称": "Keyword Strategy Name",
    "手机号": "Phone number",
    "充值前余额": "Balance before recharge",
    "充值金额": "Recharge amount",
    "充值后余额": "Balance after recharge",
    "充值类型": "Recharge Type",
    "充值方式": "Recharge method",
    "操作人": "Operator",
    "充值时间": "Recharge time",
    "确认回账时间": "Confirm return time",
    "调整前单价": "Unit price before adjustment",
    "调整后单价": "Adjusted unit price",
    "调整时间": "Adjust the time",
    "生效时间": "Effective Time",
    "生效状态": "Effective Status",
    "通道名": "Channel name",
    "省内": "Province",
    "省外": "outside the province",
    "回款前余额": "Balance before payment",
    "回款金额": "Refund Amount",
    "回款后余额": "Balance after payment",
    "回款方式": "Return method",
    "回款时间": "Payment Time",
    "归档用户": "Archive User",
    "归档金额": "Archive Amount",
    "归档类型": "Archive Type",
    "归档时间": "Archive time",
    "操作时间": "Operating Time",
    "企业名称": "Company Name",
    "所属企业组": "Company Group",
    "联系人": "Contact",
    "联系电话": "contact number",
    "企业全称": "Enterprise full name",
    "企业简称": "Enterprise Abbreviation",
    "邮箱": "Mail",
    "企业组名称": "Company Group Name",
    "发送总量": "Total amount sent",
    "未提交": "unsubmitted",
    "拦截": "intercept",
    "黑名单": "blacklist",
    "审核失败": "Audit failure",
    "发送成功": "Sent successfully",
    "发送失败": "Failed to send",
    "未知状态": "unknown status",
    "未知率": "unknown rate",
    "成功率": "Success rate",
    "协议类型": "agreement type",
    "总提交": "Total Commits",
    "成功": "success",
    "失败": "fail",
    "未知": "unknown",
    "客户经理": "account Manager",
    "统计时间": "Statistics Time",
    "提交成功率": "Submission success rate",
    "网关成功率": "Gateway success rate",
    "日期": "date",
    "表格": "sheet",
    "图表": "chart",
    "失败回执": "Failure Receipt",
    "发送数量": "Send Quantity",
    "时间段": "period",
    "错误码": "error code",
    "发送量": "Sent Amount",
    "数量": "quantity",
    "占比": "Proportion",
    "总计": "total",
    "应用价格": "App Price",
    "应用计费类型": "Application billing type",
    "通道价格": "Channel Price",
    "通道计费类型": "Channel billing type",
    "应用付费类型": "App payment type",
    "应用所属企业": "Application company",
    "应用所属销售": "App belongs to sales",
    "应收金额": "Amount Receivable",
    "实际成本": "actual cost",
    "利润": "profit",
    "号段": "Number segment",
    "所属省": "Province",
    "所属市": "City",
    "包含词": "contains words",
    "含义": "meaning",
    "提成比例": "Commission Ratio",
    "是否允许登录": "Whether to allow login",
    "所属销售": "Ownership Sales",
    "所属企业": "Affiliated Enterprise",
    "短信发送平台": "SMS sending platform",
    "登录": "Log in",
    "清空": "clear",
    "用户名": "username",
    "密码": "password",
    "请正确填写登录信息": "Please fill in the login information correctly",
    "请输入正确的用户名": "Please enter a correct username",
    "请输入正确的密码": "Please enter the correct password",
    "请输入验证码": "please enter verification code",
    "请输入账号": "Please input Username",
    "请输入密码": "Please enter your password",
    "验证码格式不正确": "Verification code format is incorrect",
    "登录成功": "login successful",
    "登录中，请稍后...": "Logging in, please wait...",
    "已配置数据库信息，无法初始化": "The database information has been configured and cannot be initialized",
    "当前温度": "Current Temperature",
    "更新时间": "update time",
    "月发送量": "Monthly sending volume",
    "上午好": "good morning",
    "请开始一天的工作吧": "Please start your day's work",
    "中午好": "good afternoon",
    "记得吃午饭哦": "Remember to eat lunch",
    "下午好": "good afternoon",
    "长时间工作要注意短暂休息哦": "After working for a long time, pay attention to taking short breaks",
    "晚上好": "Good evening",
    "加油！你是最棒哒！": "Come on! You are the best!",
    "夜深了": "Late at night",
    "注意休息哦": "Pay attention to rest",
    "统计": "statistics",
    "待审核(条)": "Pending review (piece)",
    "待发(条)": "To be sent (piece)",
    "当日发送(条)": "Same day delivery (piece)",
    "昨日发送(条)": "Sent yesterday (piece)",
    "一周发送(条)": "Send a week (piece)",
    "当月发送(条)": "Sent this month (piece)",
    "日": "day",
    "昨日": "Yesterday",
    "周": "week",
    "月": "month",
    "关闭所有": "close all",
    "关闭左侧": "Close Left",
    "关闭右侧": "Close Right",
    "关闭其他": "Close Other",
    "系统配置": "System Configuration",
    "简约白": "Simple White",
    "商务黑": "Business Black",
    "新增": "Add",
    "删除": "delete",
    "批量删除": "batch deletion",
    "编辑": "edit",
    "搜索": "search",
    "查询": "Inquire",
    "导出": "export",
    "导入": "Import",
    "刷新": "Refresh",
    "重置": "reset",
    "操作": "operate",
    "保存配置": "Save Configuration",
    "取消": "Cancel",
    "确定": "OK",
    "提交": "submit",
    "详情": "Details",
    "复制": "copy",
    "保存": "save",
    "返回": "return",
    "发送": "send",
    "补发": "reissue",
    "下载": "download",
    "上传": "Upload",
    "下一步": "Next step",
    "上一步": "Previous",
    "完成": "Finish",
    "拒绝": "reject",
    "通过": "pass",
    "开启": "open",
    "关闭": "closure",
    "展开搜索框": "Expand the search box",
    "充值": "Recharge",
    "回款": "Refund",
    "开通新产品": "Open new product",
    "配置": "Configuration",
    "确认配置": "Confirm Configuration",
    "调价": "price adjustment",
    "创建时间": "Creation Time",
    "配置列": "Configuration Columns",
    "全部状态": "All Status",
    "请选择": "please choose",
    "请输入": "please enter",
    "备注": "Remark",
    "元": "Yuan",
    "单价": "unit price",
    "条": "strip",
    "秒": "Second",
    "分": "point",
    "时": "hour",
    "移入": "move in",
    "移出": "remove",
    "开始时间": "Starting time",
    "结束时间": "End Time",
    "开通数": "Open Number",
    "销售成本价": "Sales Cost Price",
    "关键字策略": "Keyword Strategy",
    "发送时间段": "Send time period",
    "频次": "frequency",
    "账号": "account",
    "用户账号": "user account",
    "客户账号": "Customer Account",
    "应用编号": "App ID",
    "健康状态": "health status",
    "通道": "aisle",
    "包含通道": "Contains channels",
    "通道池": "channel pool",
    "通道池名称": "Channel pool name",
    "通道池类型": "Channel pool type",
    "通道编号": "Channel number",
    "账号状态": "Account Status",
    "产品名称": "product name",
    "用户类型": "user type",
    "付费类型": "Payment Type",
    "计费类型": "Billing Type",
    "计费方式": "Billing method",
    "付费方式": "Payment methods",
    "公司": "company",
    "公司名称": "Company Name",
    "客户类型": "Customer Type",
    "账户余额": "Account Balance",
    "消费金额": "Amount of consumption",
    "销售": "Sale",
    "售价": "Price",
    "运营商": "operator",
    "发送优先级": "Send Priority",
    "子号": "subnumber",
    "用户子号": "User ID",
    "启用状态": "Enabled Status",
    "产品状态": "product status",
    "产品类型": "product type",
    "指定扩展号": "Specify extension number",
    "强制审核": "Compulsory Audit",
    "黑名单策略": "Blacklist Policy",
    "关键字匹配策略": "Keyword Matching Strategy",
    "发送时间限制": "Send time limit",
    "频限": "frequency limit",
    "补发策略": "Reissue Strategy",
    "开始补发时间": "Start reissuing time",
    "结束补发时间": "End reissue time",
    "最大补发时间": "Maximum reissue time",
    "补发通道池": "Reissue channel pool",
    "补发模式": "Reissue Mode",
    "补发错误码": "Reissue error code",
    "接口类型": "Interface Type",
    "状态": "state",
    "绑定IP": "Bind IP",
    "连接数": "Number of Connections",
    "最大速度": "Maximum speed",
    "回执推送方式": "Receipt push method",
    "回执推送地址": "Receipt push address",
    "上行推送方式": "Uplink push method",
    "上行推送地址": "Uplink push address",
    "通道池ID": "Channel Pool ID",
    "供应商": "supplier",
    "基本配置": "basic configuration",
    "安全参数配置": "Security parameter configuration",
    "接口参数配置": "Interface parameter configuration",
    "通道池配置": "Channel pool configuration",
    "复制接口参数": "Copy Interface Parameters",
    "注意：请仔细检查配置项后再点击保存配置！": "Note: Please check the configuration items carefully before clicking Save Configuration!",
    "策略名称": "Strategy Name",
    "补发策略名称": "Reissue Policy Name",
    "补发时间段": "Reissue time period",
    "添加补发错误码": "Add reissue error code",
    "通道名称": "Channel name",
    "接入协议": "Access Protocol",
    "接入号码": "Access Number",
    "速率": "rate",
    "通道待发量": "Channel pending volume",
    "支持省份": "Support Provinces",
    "服务器IP": "Server IP",
    "端口": "port",
    "企业代码": "Enterprise code",
    "业务代码": "Business code",
    "是否含税": "Whether tax is included",
    "落地省网": "Landing Provincial Network",
    "下发总速度": "Total sending speed",
    "通道并发数": "Channel concurrent number",
    "省内价格": "Provincial Price",
    "省外价格": "Price outside the province",
    "计费字数": "Billing word count",
    "单条计费字数": "Single billing word count",
    "上行匹配模式": "uplink matching mode",
    "配置通道池": "Configure channel pool",
    "可配置通道": "Configurable Channel",
    "权重": "Weights",
    "产品编号": "Product Number",
    "接收手机": "Receive phone",
    "拦截原因": "Intercept reason",
    "签名": "sign",
    "内容": "content",
    "发送内容": "Send Content",
    "短信内容": "SMS content",
    "发送号码": "Send Number",
    "归属地": "Attribution",
    "回复时间": "Response Time",
    "推送次数": "Push times",
    "回复内容": "Reply content",
    "计费条数": "Billing number",
    "接收状态": "Receive Status",
    "短信组ID": "SMS group ID",
    "短信ID": "SMS ID",
    "发送状态": "send status",
    "响应代码": "Response Code",
    "推送回执": "Push Receipt",
    "回执时间": "Receipt Time",
    "完整耗时": "Complete time-consuming",
    "推送上行": "Push upstream",
    "类型": "type",
    "匹配用户": "Match User",
    "创建人": "founder",
    "转移": "Transfer",
    "定时刷新": "Timed Refresh",
    "开始": "start",
    "停止": "stop",
    "选中批量": "Select Batch",
    "普通模式": "Normal Mode",
    "智能模式": "Smart Mode",
    "发送人": "sender",
    "号码个数": "number of numbers",
    "序号": "serial number",
    "审核结果": "Audit results",
    "审核人": "Reviewer",
    "审批时间": "Processing time",
    "关键字列表": "Keyword List",
    "关键字类型": "Keyword Type",
    "所属用户": "Owner",
    "内容关键字": "Content Keyword",
    "添加人": "add a person",
    "添加时间": "add time",
    "级别": "level",
    "方向": "direction",
    "顺序": "order",
    "新增用户": "New users",
    "编辑用户": "Edit User",
    "新增补发策略": "New Reissue Policy",
    "编辑补发策略": "Edit Reissue Policy",
    "编辑标准协议通道": "Edit Standard Protocol Channel",
    "新增通道池": "Add channel pool",
    "编辑通道池": "Edit Channel Pool",
    "加入黑名单": "add to blacklist",
    "新增关键字": "Add Keyword",
    "编辑关键字": "Edit Keyword",
    "新增关键字类型": "Add Keyword Type",
    "编辑关键字类型": "Edit Keyword Type",
    "新增关键字策略": "Add Keyword Strategy",
    "编辑关键字策略": "Edit Keyword Strategy",
    "拖拽进行排序": "Drag and drop to sort",
    "模板内容": "Template Content",
    "所有关键字类型": "All keyword types",
    "策略所含关键字类型": "Type of keywords contained in the strategy",
    "tip:关键字类型的先后加入顺序决定在该策略中的优先级": "tip: The order in which keyword types are added determines the priority in this strategy",
    "新增标准协议通道": "Add standard protocol channel",
    "新增关键字匹配策略": "Add Keyword Matching Strategy",
    "测试": "test",
    "新增企业": "New business",
    "新增企业组": "New Enterprise Group",
    "新建节点": "New Node",
    "编辑节点": "Edit Node",
    "删除节点": "Delete Node",
    "导出数据": "export data",
    "允许": "allow",
    "不允许": "not allowed",
    "设置密码": "set password",
    "点击生成随机密码": "Click to generate a random password",
    "已开通产品": "Activated product",
    "产品列表": "Product List",
    "确认开通": "Confirm activation",
    "注意：请仔细检查配置项后再点击保存配置！": "Note: Please check the configuration items carefully before clicking Save Configuration!",
    "点击返回（或按下esc）": "Click to return (or press esc)",
    "四级": "Level 4",
    "三级": "Level 3",
    "二级": "Level 2",
    "扩展号": "extension number",
    "用户扩展码模式": "User extension code mode",
    "复制接口参数": "Copy Interface Parameters",
    "注：输入IP段格式【192.168.0.0/24】": "Note: Enter the IP segment format 【192.168.0.0/24】",
    "启用": "enable",
    "停用": "deactivate",
    "产品调价": "Product Price Adjustment",
    "您正在删除此用户，要继续吗?": "You are deleting this user, do you want to continue?",
    "提示": "hint",
    "继续": "continue",
    "您正在删除此用户下的产品，要继续吗?": "You are deleting products under this user, do you want to continue?",
    "取消删除": "Undelete",
    "请确认通道池配置": "Please confirm the channel pool configuration",
    "接口参数配置<回执推送地址>未填写": "Interface parameter configuration <receipt push address> is not filled",
    "接口参数配置<上行推送地址>未填写": "Interface parameter configuration <uplink push address> is not filled",
    "请选择补发时间段": "Please select a reissue time period",
    "业务类型": "business type",
    "对接协议": "Docking Agreement",
    "接入号": "Access Number",
    "链接数": "Links",
    "复制成功": "Copy success",
    "一天": "one day",
    "三天": "three days",
    "价格": "price",
    "所含通道": "Included channels",
    "取消挂载": "Unmount",
    "操作流程:<br/>1.点击挂载通道池<br/>2.点击筛选,查询相应的通道池<br/>3.点击要挂载通道池的“确认挂载”按钮,进行挂载<br/>4.被挂载的通道池添加到当前通道列表中": "Operation process:<br/>1. Click to mount the channel pool<br/>2. Click to filter to query the corresponding channel pool<br/>3. Click the Confirm Mount button of the channel pool to be mounted to proceed Mount<br/>4. Add the mounted channel pool to the current channel list",
    "小时": "Hour",
    "要继续吗?": "Do you want to continue?",
    "您正在删除此策略，要继续吗?": "You are deleting this policy, do you want to continue?",
    "标准协议通道": "Standard Protocol Channel",
    "当": "cur",
    "最": "max",
    "选择上方运营商生成通道编号": "Select the upper operator to generate the channel number",
    "付费号码": "Pay Number",
    "请输入下发速度1-10000": "Please enter delivery speed 1-10000",
    "导入": "Import",
    "导出": "export",
    "通道转移": "Channel Transfer",
    "当前通道": "Current Channel",
    "目标通道": "Target channel",
    "当前通道正在使用，禁止删除！": "The current channel is in use, it is forbidden to delete!",
    "您已进入通道测试模式": "You have entered channel test mode",
    "导出成功，在新增标准协议通道导入使用": "Export is successful, import and use in the new standard protocol channel",
    "导入成功": "Imported successfully",
    "启用中": "Activating",
    "运营号段": "Operation number segment",
    "加入": "join in",
    "当前通道池正在使用，禁止删除！": "The current channel pool is in use and cannot be deleted!",
    "通道池中已包含该通道！！！": "The channel is already included in the channel pool!!!",
    "当前为通道编号": "current channel number",
    "的短信发送测试模式，点击右侧关闭测试模式": "SMS sending test mode, click on the right to close the test mode",
    "文件上传": "File Upload",
    "点击上传文件": "Click to upload file",
    "号码文件支持txt、xls、xlsx格式文件,只需要在第一列填写号码即可(txt文件每行1个号码),最多输入10万个号码": "The number file supports txt, xls, and xlsx format files. You only need to fill in the number in the first column (1 number per line in the txt file), and you can enter up to 100,000 numbers.",
    "当前输入": "Currently input",
    "个号码": "numbers",
    "个字": "character",
    "短信签名": "SMS signature",
    "70个字以内算1条,超过70个字按每条67个字拆分，共拆分": "Within 70 characters count as 1 article, if more than 70 characters are divided according to 67 characters per article, split in total",
    "扩展": "extension",
    "自定义扩展号": "Custom extension number",
    "缺少应用id！": "Missing app id!",
    "缺少短信内容！": "Missing message content!",
    "缺少手机号！": "Missing phone number!",
    "缺少必要参数！": "A required parameter is missing!",
    "已关闭测试模式": "Test mode turned off",
    "开始时间不能大于结束时间！": "The start time cannot be greater than the end time!",
    "提交时间": "submission time",
    "删除选中": "delete selected",
    "新增黑白名单": "Add black and white list",
    "所属签名": "Ownership Signature",
    "编辑黑白名单": "Edit black and white list",
    "秒后刷新": "Refresh after seconds",
    "提取关键字": "Extract Keyword",
    "通道池转移": "Channel pool transfer",
    "目标通道池": "Target channel pool",
    "健康": "healthy",
    "不健康": "Unhealthy",
    "最短间隔10秒刷新": "Refresh at a minimum interval of 10 seconds",
    "返还类型": "Return Type",
    "时间范围": "time limit",
    "回账": "Refund",
    "您正在删除此企业组，要继续吗?": "You are deleting this business group, do you want to continue?",
    "您正在删除此企业，要继续吗?": "You are deleting this business, do you want to continue?",
    "客户经理发送统计报表": "Account Manager Sends Statistical Report",
    "通道发送统计报表": "Channel sending statistics report",
    "客户发送统计报表": "Customer Sending Statistical Report",
    "按天发送统计报表": "Send statistical reports by day",
    "错误码统计报表": "Error Code Statistical Report",
    "利润统计报表": "Profit Statistical Report",
    "还原": "reduction",
    "省份合计统计": "Provincial Total Statistics",
    "单省份日期统计": "Single province date statistics",
    "分省统计报表": "Statistical report by province",
    "0-5秒": "0-5 seconds",
    "6-7秒": "6-7 seconds",
    "8-10秒": "8-10 seconds",
    "11秒以上": "more than 11 seconds",
    "发送速度统计报表": "Send Speed Statistics Report",
    "签名统计报表": "Signature Statistical Report",
    "新增号段": "Add Number Segment",
    "号码段": "Number section",
    "移动": "move",
    "联通": "Unicom",
    "电信": "telecommunications",
    "回车为一项输入": "Enter is an input",
    "应用付费方式": "Application payment method",
    "平均每日发送": "average daily send",
    "发送结果": "Send Results",
    "天": "day",
    "年": "Year",
    "当前角色": "Current Role",
    "切换为": "Switch to",
    "个人信息": "personal information",
    "登 出": "Sign out",
    "删除成功": "success deleted",
    "关键字": "keyword",
    "关键字内容": "Keyword Content",
    "关键字匹配类型": "Keyword Match Type",
    "选择产品": "Select Product",
    "修改密码": "change Password",
    "退出": "quit",
    "仪表盘": "dashboard",
    "发送短信": "send messages",
    "发送明细": "Send Details",
    "上行回复": "uplink reply",
    "应用管理": "App Management",
    "通讯录": "Contacts",
    "旧密码": "Old Password",
    "至少6位字符": "at least 6 characters",
    "新密码": "New Password",
    "不用了": "No",
    "密码格式不正确": "Password format is incorrect",
    "请输入密码": "Please enter your password",
    "新旧密码不能相同": "The old and new passwords cannot be the same",
    "用户短信发送平台": "User SMS sending platform",
    "验证码": "Verification Code",
    "改变主题": "Change Theme",
    "注册": "register",
    "字数统计": "Word Count",
    "号码文件": "number file",
    "点击上传号码文件": "Click to upload number file",
    "子码": "subcode",
    "切换系统语言": "switch system language",
    "定时发送": "Scheduled Send",
    "选择时间": "selection period",
    "立即发送": "send immediately",
    "请选择时间": "Please select a time",
    "统计管理": "statistics management",
    "不选择时间则立即发送": "Send immediately if no time is selected",
    "缺少必填项！": "Required fields are missing!",
    "加载中": "Loading",
    "下载示例文件": "Download sample files",
    "导入联系人": "import contacts",
    "示例文件": "sample file",
    "客户发送统计": "Customer sending statistics",
    "错误码统计": "Error code statistics",
    "签名发送统计": "Signature sending statistics",
    "上传成功": "uploaded successfully",
    "上传失败": "upload failed",
    "下载完成": "Download completed",
    "号码文件支持xls、xlsx格式文件,只需要在第一列填写号码即可,最多输入10万个号码": "The number file supports xls and xlsx format files, you only need to fill in the number in the first column, and you can enter a maximum of 100,000 numbers",
    "当前用户": "Current user",
    "必选": "required",
    "必填": "required",
    "用户管理": "User Management",
    "昵称": "Nick name",
    "注册用户": "registered user",
    "用户角色": "User Role",
    "角色": "Role",
    "发送时间": "Send time",
    "通讯录名称": "Contact Name",
    "分组个数": "Number of groups",
    "联系人个数": "Number of Contacts",
    "添加分组": "Add group",
    "导出分组": "Export group",
    "添加联系人": "Add contacts",
    "分组": "Group",
    "分组信息": "Group Information",
    "下载示例文件": "Download sample files",
    "导入联系人": "Import contacts",
    "确认编辑": "Confirm Edit",
    "分组名称": "Group Name",
    "分组类型": "Group Type",
    "联系人信息": "contact information",
    "姓名": "Name",
    "首字母": "Initials",
    "昵称": "Nick name",
    "传真号码": "fax number",
    "邮箱地址": "email address",
    "生日": "Birthday",
    "公司名称": "Company Name",
    "联系地址": "contact address",
    "编辑并保存": "Edit and save",
    "通讯录发送": "Contact Send",
    "名称": "Name",
    "请先清空手机号码": "Please clear the phone number first",
}